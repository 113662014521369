import React from 'react'
import {
  Segment,
  Container,
  Image,
  Header,
  Button,
  Icon,
} from 'semantic-ui-react'
import PageHeader from '../components/PageHeader'
import Layout from '../components/layout'
import { FormattedMessage } from 'gatsby-plugin-intl'

import './index.css'
import apn_logo from '../images/logo-consulting-partner-aws-mangrove.svg'
import avis_0 from '../images/avis/avis-0.jpg'
import avis_1 from '../images/avis/avis-1.jpg'

const HomepagePrimary = () => (
  <div>
    <Segment vertical>
      <Segment as="section" basic>
          <Image src={avis_0} size="massive" centered alt="Avis" />
          <Image src={avis_1} size="massive" centered alt="Avis" />
      </Segment>
      <Segment as="section" basic>
        <Container text>
          <a
            href="https://www.snef.fr/sneftechnologies/images/2024%2002%2023%20-%20Trait%C3%A9%20fusion%20Mangrove%20Snef%20Techno%20vDEF.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Consultez le traité de fusion entre Snef Technologies et Mangrove
          </a>
        </Container>
      </Segment>
    </Segment>

    <Segment vertical inverted className="SubHeader">
      <div className="bg">
        <Segment vertical>
          <Container text>
            <p>
              <FormattedMessage id="banner-homepage-1" />
            </p>
          </Container>
        </Segment>
      </div>
    </Segment>

    <Segment as="section" basic>
      <Container>
        <a
          href="https://aws.amazon.com/partners/find/partnerdetails/?n=Mangrove%20Consulting&id=0010h00001aCXe0AAG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            src={apn_logo}
            className="partner"
            size="medium"
            centered
            alt="APN"
          />
        </a>
      </Container>
    </Segment>
  </div>
)

const Homepage = () => (
  <Layout>
    <div className="IndexPage">
      <PageHeader>  
        <Header inverted as="h1">
          <FormattedMessage id="title-homepage" />
        </Header>
        <p>
          <FormattedMessage id="subheader-homepage" />
        </p>
        <p>
          <FormattedMessage id="subheader-homepage-2" />
        </p>
        <Button
          secondary
          size="big"
          as="a"
          href="https://mangroveai.typeform.com/to/ZZFO77"
          target="_blank"
          rel="noopener"
        >
          <Icon name="mail" /> <FormattedMessage id="button-contact" />
        </Button>
      </PageHeader>
      <HomepagePrimary />
    </div>
  </Layout>
)

export default Homepage
